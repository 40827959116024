import { Router, createRouter as _createRouter, createWebHistory } from 'vue-router'

import { Config } from '@/config/types'

const createRouter = (_config: Config): Router => {
  return _createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
      {
        path: '/auth/callback',
        name: 'auth-callback',
        components: {
          main: () => import('@/components/LoadingBlock.vue'),
        },
        meta: {
          skipAuthentication: true,
        },
      },
      {
        path: '/register',
        name: 'auth-register',
        components: {
          main: () => import('@/components/LoadingBlock.vue'),
        },
        meta: {
          toRegistration: true,
        },
      },
      {
        path: '/document/debug',
        name: 'debug',
        components: {
          header: () => import('@/StowageHeader.vue'),
          main: () => import('@/pages/document/Debug.vue'),
        },
      },
      {
        path: '/',
        name: 'Home',
        components: {
          header: () => import('@/components/Header.vue'),
          main: () => import('@/components/search-results/UniversalSearchResults.vue'),
        },
      },
      {
        path: '/onboarding',
        name: 'Onboarding',
        components: {
          main: () => import('@/pages/onboarding/Onboarding.vue'),
        },
      },
      {
        path: '/preview/:type/:uri',
        name: 'Preview',
        components: {
          main: () => import('@/components/PreviewTemplate.vue'),
        },
        props: {
          main: (route) => ({
            type: route.params.type,
            uri: decodeURIComponent(route.params.uri.toString()),
            isAdded: false,
          }),
        },
      },
      {
        path: '/proposal/:uri/builder',
        name: 'Proposal Builder',
        components: {
          main: () => import('@/pages/document/ProposalEditor.vue'),
        },
        props: {
          main: (route) => ({
            uri: decodeURIComponent(route.params.uri.toString()),
          }),
          header: (route) => ({
            uri: decodeURIComponent(route.params.uri.toString()),
          }),
        },
      },
      {
        path: '/itinerary/:uri/builder',
        name: 'Itinerary Builder',
        components: {
          main: () => import('@/pages/document/ItineraryEditor.vue'),
        },
        props: {
          header: (route) => ({
            uri: decodeURIComponent(route.params.uri.toString()),
          }),
          main: (route) => ({
            uri: decodeURIComponent(route.params.uri.toString()),
          }),
        },
      },
    ],
  })
}

export default createRouter
