<script setup lang="ts">
import { defineAsyncComponent } from 'vue'

import Loading from '@/components/Spinner.vue'

const AppContext = defineAsyncComponent(() => import('./AppContext.vue'))
</script>
<template>
  <Suspense>
    <template #fallback>
      <Loading class="h-screen" />
    </template>
    <AppContext>
      <main class="page-main bg-white dark:bg-gray-900">
        <RouterView />
      </main>
    </AppContext>
  </Suspense>
</template>
