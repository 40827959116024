import { AuthenticationContext } from './types'

export class AuthenticationProvider {
  private static instance: () => AuthenticationContext

  public static setInstance(instance: () => AuthenticationContext) {
    this.instance = instance
  }

  public static getInstance(): () => AuthenticationContext {
    return this.instance
  }
}
