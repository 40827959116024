import { ShadowState } from '../types'
import { Backup } from './backup'

/**
 * Represents the shadow document in the differential synchronization algorithm
 */
export class Shadow<T> {
  /**
   * The content held by the shadow
   */
  private content: T
  /**
   * The local revision
   */
  private localRev: number
  /**
   * The latest ackwnoledged remote revision
   */
  private remoteRev: number

  constructor(content: T, state?: ShadowState) {
    this.content = state ? state.content : content
    this.localRev = state ? state.localRev : 0
    this.remoteRev = state ? state.remoteRev : 0
  }

  /**
   * Get the shadow document content
   *
   * @returns the content held by the shadow
   */
  public getContent(): T {
    return this.content
  }

  /**
   * Set the shadow content
   *
   * @param content the content to set to the shadow
   * @returns {this}
   */
  public setContent(content: T): Shadow<T> {
    this.content = JSON.parse(JSON.stringify(content))
    return this
  }

  /**
   * Get the shadow local revision
   *
   * @returns the local revision
   */
  public getLocalRev(): number {
    return this.localRev
  }

  /**
   * Set the local revision for this shadow
   *
   * @param localRev the local revision to set to the shadow
   * @returns {this}
   */
  public setLocalRev(localRev: number): Shadow<T> {
    this.localRev = localRev
    return this
  }

  /**
   * Get the remote revision of this shadow document
   *
   * @returns the remote revision
   */
  public getRemoteRev(): number {
    return this.remoteRev
  }

  public setRemoteRev(remoteRev: number): Shadow<T> {
    this.remoteRev = remoteRev
    return this
  }

  /**
   * Increase the local revision
   *
   * @returns {this}
   */
  public increaseLocalRev(): Shadow<T> {
    this.localRev += 1
    return this
  }

  /**
   * Increase the remote revision
   * @returns {this}
   */
  public increaseRemoteRev(): Shadow<T> {
    this.remoteRev += 1
    return this
  }

  /**
   * Copy the content from this shadow to a backup document.
   * The local revision is copied along
   *
   * @param backup the backup document to copy the content to
   * @returns {this}
   */
  public copyTo(backup: Backup<T>): Shadow<T> {
    backup.setContent(this.content)
    return this
  }
}
