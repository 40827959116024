import { ApolloClient } from '@apollo/client'

export class ApolloClientProvider {
  private static instance: () => Promise<ApolloClient<any>>

  public static setInstance(instance: () => Promise<ApolloClient<any>>) {
    this.instance = instance
  }

  public static getInstance(): () => Promise<ApolloClient<any>> {
    return this.instance
  }
}
