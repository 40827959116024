import * as jsonpatch from 'fast-json-patch'
import { Operation } from 'fast-json-patch'

import { Callable } from '../../lang/functional.types'
import { DiffSyncState } from '../types'
import { Backup } from './backup'
import { Document as _Document } from './document'
import { Edit, EditInit } from './edit'
import { EditStack } from './edit.stack'
import { Shadow } from './shadow'

/**
 * Implements the Differential synchronization algorithm
 */
export class DiffSync<T> {
  private ref: string
  private shadow: Shadow<T>
  private backup: Backup<T>
  private outgoingEditStack: EditStack

  constructor(content: T, ref: string, state?: DiffSyncState) {
    this.ref = state ? state.ref : ref
    this.shadow = new Shadow<T>(JSON.parse(JSON.stringify(content)), state ? state.shadow : undefined)
    this.backup = new Backup<T>(JSON.parse(JSON.stringify(content)), state ? state.backup : undefined)
    this.outgoingEditStack = {
      edits: state ? state.outgoingEditStack.edits.map((edit: EditInit) => new Edit(edit)) : [],
      remoteRev: this.shadow.getRemoteRev(),
    }
  }

  public snapshot(): DiffSyncState {
    return {
      ref: this.ref,
      shadow: {
        localRev: this.shadow.getLocalRev(),
        remoteRev: this.shadow.getRemoteRev(),
        content: this.shadow.getContent(),
      },
      backup: {
        localRev: this.backup.getLocalRev(),
        content: this.backup.getContent(),
      },
      outgoingEditStack: {
        edits: this.outgoingEditStack.edits.map((edit: Edit) => ({
          revision: edit.getRevision(),
          operations: edit.getOperations(),
        })),
      },
    }
  }

  /**
   * Clear all the edits with a revision that is equal or lower than the supplied
   * remote revision argument
   *
   * @param remoteRev the revision to clear the edits for
   */
  public pruneOutgoingEdits(remoteRev: number): void {
    this.outgoingEditStack.edits = this.outgoingEditStack.edits.filter((edit) => {
      return edit.getRevision() > remoteRev
    })
  }

  /**
   * Get the edit stack for this diff sync
   */
  public getEditStack(): EditStack {
    return this.outgoingEditStack
  }

  /**
   * Get the content of the shadow document
   */
  public getShadowContent(): T {
    return this.shadow.getContent()
  }

  /**
   * Get the content of the backup document
   */
  public getBackupContent(): T {
    return this.backup.getContent()
  }

  /**
   * Get the shadow document local revision
   */
  public getShadowLocalRev(): number {
    return this.shadow.getLocalRev()
  }

  /**
   * Get the shadow document remote revision
   */
  public getShadowRemoteRev(): number {
    return this.shadow.getRemoteRev()
  }

  /**
   * Get the backup document local revision
   */
  public getBackupLocalRev(): number {
    return this.backup.getLocalRev()
  }

  /**
   * Get the reference (unique identifier) for this diff sync
   */
  public getRef(): string {
    return this.ref
  }

  /**
   * Implements the part of the differential synchornization algorithm that
   * handles the incoming edit stack changes.
   * see (https://neil.fraser.name/writing/sync/)
   *
   * @param incomingEditStack a stack of incoming edits to handle
   * @param document the document to handle the edit stack against
   * @returns the number of changes that were applied to the document and the shadow
   */
  public handleIncomingEdits(incomingEditStack: EditStack, document: _Document<T>, args?: HandlerArgs): number {
    // find out if there are operations in the incoming edit stack, otherwise we are dealing with an ack
    const hasOperations =
      incomingEditStack.edits
        .map((edit: Edit) => edit.getOperations())
        .reduce((acc, comb) => {
          return acc.concat(comb)
        }, []).length > 0

    if (!hasOperations) {
      // if there are no operations this is an ack, there is nothing to handle here
      if (args && args.onAck) {
        args.onAck()
      }
      return 0
    }

    // if the edit remote rev is not matching the shadow localRev then rollback
    if (incomingEditStack.remoteRev !== this.shadow.getLocalRev()) {
      // rollback, clear the edits and copy the backup to the shadow
      this.backup.copyTo(this.shadow)
      this.shadow.setLocalRev(this.backup.getLocalRev())
      this.outgoingEditStack.edits = []
    }

    let changesCount = 0
    incomingEditStack.edits.forEach((edit) => {
      // drop all the edits where the remoteRev is lower than the localRev from the incoming edit
      if (edit.getRevision() < this.shadow.getRemoteRev()) {
        // skipping
        return
      }

      if (edit.getOperations().length === 0) {
        // skip
        return
      }
      // ok now apply the patch to the shadow
      this.shadow.setContent(jsonpatch.applyPatch(this.shadow.getContent(), edit.getOperations()).newDocument)
      // increment the shadow remote rev
      this.shadow.increaseRemoteRev()
      // patch the document
      document.setContent(jsonpatch.applyPatch(document.getContent(), edit.getOperations()).newDocument)
      // copy the shadow to the backup
      this.shadow.copyTo(this.backup)
      this.backup.setLocalRev(this.shadow.getLocalRev())
      changesCount++
      // }
    })

    return changesCount
  }

  /**
   * Implements the part of the differential synchronization algorithm that
   * compares the document with the shadow to produce a diff and store it in
   * the edits stack. See (https://neil.fraser.name/writing/sync/)
   *
   * @param document the document to compare to the shadow
   * @returns this diff sync edit stack
   */
  public documentChange(document: _Document<T>): EditStack {
    // do a diff with the shadow
    const operations: Operation[] = jsonpatch.compare(
      this.shadow.getContent() as Object | Array<any>,
      document.getContent() as Object | Array<any>,
    )

    this.outgoingEditStack.edits.push(
      new Edit({
        revision: JSON.parse(JSON.stringify(this.shadow.getLocalRev())),
        operations: operations,
      }),
    )

    this.outgoingEditStack.remoteRev = this.shadow.getRemoteRev()

    if (operations.length !== 0) {
      document.copyTo(this.shadow)
      this.shadow.increaseLocalRev()
    }

    return this.outgoingEditStack
  }

  // public withExisting(currentContent: T, remoteContent: T): Edit [] {
  //   // set the document with existing, set the backup with existing
  //   this.backup = new Backup<T>(currentContent)
  //   // set the shadow with remote
  //   this.shadow = new Shadow<T>(remoteContent)
  //   // clear stack of edits
  //   this.edits = []
  //   // call document change
  //   return this.documentChange(currentContent)
  // }
}

/**
 * Handler arguments to pass to the incoming edits handler method
 */
export declare type HandlerArgs = {
  /**
   * A callback that runs when the incoming edits message is an ack
   */
  onAck?: Callable
}
