import { createRouter, createWebHistory } from 'vue-router'

import Presentations from '@/app/trips/library/Presentations.vue'
import Routes from '@/app/trips/library/Routes.vue'
import Yachts from '@/app/trips/library/Yachts.vue'

const routerV2 = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/trips/library',
      name: 'Dashboard',
      children: [
        {
          path: 'presentations',
          name: 'presentations',
          component: () => import('@/app/trips/Dashboard.vue'),
          props: {
            view: Presentations,
          },
        },
        {
          path: 'routes',
          name: 'routes',
          component: () => import('@/app/trips/Dashboard.vue'),
          props: {
            view: Routes,
          },
        },
        {
          path: 'yachts',
          name: 'yachts',
          component: () => import('@/app/trips/Dashboard.vue'),
          props: {
            view: Yachts,
          },
        },
      ],
    },
  ],
  scrollBehavior(_to, _from, _savedPosition) {
    return {
      // always scroll to top on any route change.
      top: 0,
      behavior: 'smooth',
    }
  },
})

export default routerV2
