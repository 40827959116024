import { Shadow } from './shadow'

/**
 * Represents the Document object in the differential synchornization algorithm
 */
export class Document<T> {
  /**
   * The content held by this document
   */
  protected content: T

  constructor(content: T) {
    this.content = content
  }

  /**
   * Get the document content
   *
   * @returns the content held by this document
   */
  public getContent(): T {
    return this.content
  }

  public getTypedContent<R>(): R {
    return this.content as unknown as R
  }

  public setContent(content: T): Document<T> {
    this.content = JSON.parse(JSON.stringify(content))
    return this
  }

  /**
   * Copy the document content to the shadow.
   * When the document content is copied over to the shadow
   * the localRev version is increased
   *
   * @param shadow the shadow to copy the content to
   */
  public copyTo(shadow: Shadow<T>): void {
    shadow.setContent(this.content)
  }
}
