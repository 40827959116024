<script setup lang="ts">
import { OnClickOutside } from '@vueuse/components'

import { OutlineXMark } from '@ankor-io/icons/outline'

import { useModal } from '@/modal/useModal'

const { showCloseButton, canCloseOnBackdrop, updateModalState } = useModal()
</script>
<template>
  <div
    tabindex="1"
    aria-hidden="true"
    class="fixed top-0 left-0 right-0 z-50 w-full overflow-x-hidden overflow-y-auto inset-0 h-full backdrop-blur-[2px] p-2"
    @keydown.esc="canCloseOnBackdrop && updateModalState(false)"
  >
    <div class="relative shrink-0 top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 w-fit max-h-[calc(100vh-1rem)]">
      <!-- Modal content -->
      <OnClickOutside @trigger="canCloseOnBackdrop && updateModalState(false)">
        <div
          id="modal"
          class="relative bg-white border border-gray-200 dark:bg-gray-800 dark:border-gray-600 text-gray-900 dark:text-gray-50 rounded-lg shadow-md p-8"
        >
          <div v-if="showCloseButton" class="absolute right-8 top-10 flex justify-end">
            <OutlineXMark
              class="cursor-pointer w-3 h-3 self-center stroke-gray-900 hover:stroke-primary-600 dark:stroke-gray-400 dark:hover:stroke-primary-600"
              @click="updateModalState(false)"
            />
          </div>
          <slot></slot>
        </div>
      </OnClickOutside>
    </div>
  </div>
</template>
