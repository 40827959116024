import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client/core'
import { onError } from '@apollo/client/link/error'
import { App } from 'vue'
import { AuthenticationContext } from '@/iam/types'
import { provideApolloClient, useApolloClient } from '@vue/apollo-composable'
import { ApolloClientPlugin } from './types'
import { ApolloClientProvider } from '@/apollo/ApolloClientProvider'

export const apolloClientInstance = (function () {
  let _authenticationContext: AuthenticationContext
  /**
   * httpLink apolloClient
   * @param token the jwt token of the user
   * @returns ApolloLink
   */
  const httpLink = (token: string): ApolloLink =>
    createHttpLink({
      uri: '/api/graphql/',
      headers: {
        authorization: `Bearer ${token}`,
      },
    })

  // InMemoryCache for apolloClient
  const cache: InMemoryCache = new InMemoryCache()

  // Handle errors
  const errorLink = onError((error) => {
    if (process.env.NODE_ENV !== 'production') {
      console.debug(error)
    }
  })

  /**
   * Get client to be able to make GraphQL queries
   * when not in vue context
   */
  const getClient = async () => {
    const token: string = (await _authenticationContext.getToken())!
    const newApolloClient: ApolloClient<NormalizedCacheObject> = new ApolloClient({
      link: errorLink.concat(httpLink(token)),
      cache,
      defaultOptions: {
        query: {
          fetchPolicy: 'network-only',
        },
      },
    })

    // Provider for the default Apollo client in execution context
    provideApolloClient(newApolloClient)

    return useApolloClient().resolveClient()
  }

  /**
   * Create a plugin to provide Apollo Client
   * to perform queries in the app
   * @returns a plugin installer
   */
  const createApolloClient = (authenticationContext: AuthenticationContext): ApolloClientPlugin => {
    setAuthenticationContext(authenticationContext)
    const apolloClient: () => Promise<ApolloClient<any>> = () => getClient()
    ApolloClientProvider.setInstance(apolloClient)
    return {
      install: (app: App): void => {
        app.config.globalProperties._apolloClient = apolloClient
        app.provide('apolloClient', apolloClient)
      },
    }
  }

  const setAuthenticationContext = (authContext: AuthenticationContext): void => {
    _authenticationContext = authContext
  }

  return {
    createApolloClient,
  }
})()
