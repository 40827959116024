import { Operation } from 'fast-json-patch'

/**
 * Type that allows to construct an {@link Edit} object
 */
export type EditInit = {
  revision: number
  operations: Operation[]
}

/**
 * Represents the structure of an edit object
 */
export class Edit {
  /**
   * This edit revision number
   */
  private revision: number
  /**
   * A list of operations describing the document changes
   */
  private operations: Operation[]

  constructor(init: EditInit) {
    this.revision = init.revision
    this.operations = init.operations
  }

  public getRevision(): number {
    return this.revision
  }

  public getOperations(): Operation[] {
    return this.operations
  }
}
