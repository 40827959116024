import * as _ from '@ankor-io/common/auth/client/types'

export const AuthenticationContextKey = _.AuthenticationContextKey

export declare type PrincipalIdentity = _.PrincipalIdentity

export type AuthenticationClientOptions = _.AuthenticationClientOptions & {
  /**
   * The kinde connection id to enable custom sign in & sign up page
   */
  connection_id: string
}

export interface AuthenticationContext extends _.AuthenticationContext {}
