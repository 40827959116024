import { KindeOrganizations } from '@kinde-oss/kinde-auth-pkce-js'
import { App, InjectionKey } from 'vue'
import { RouteLocationNormalized } from 'vue-router'

export const AuthenticationContextKey = Symbol() as InjectionKey<AuthenticationContext>

export declare type PrincipalIdentity = {
  id: string | null
  given_name: string | null
  family_name: string | null
  email: string | null
}

export type AuthenticationClientOptions = {
  audience?: string
  client_id?: string
  org_code?: string
  default_org_code?: string
  redirect_uri?: string
  domain: string
  is_dangerously_use_local_storage?: boolean
  logout_uri?: string
  on_redirect_callback?: (user: PrincipalIdentity, appState?: any) => void
  scope?: string
}

export type IAMPredicates = {
  beforeEach(to: RouteLocationNormalized): Promise<boolean | { path: string }>
}

export interface AuthenticationContext {
  /**
   * Send the user to the login page.
   */
  login?(options?: any): Promise<void>
  /**
   * Send the user to the registeration page.
   */
  register?(options?: any): Promise<void>
  /**
   * Send the user to the login page.
   */
  redirectToLogin(options?: any): Promise<void>

  /**
   * Send the user to the signup page.
   */
  redirectToRegister?(options?: any): Promise<void>

  /**
   * Send the user to the logout flow.
   */
  logout(): Promise<void>

  /**
   * Get the user information
   * @returns the KindeUser
   */
  getUser(): PrincipalIdentity

  /**
   * Get the user auth token or redirect to login when failing to get the token
   * @returns the auth token.
   */
  getToken(): Promise<string | undefined>

  /**
   * Get the user organizations
   */
  getUserOrganizations(): Promise<KindeOrganizations>

  /**
   * Vue app install
   * @param app
   */
  install(app: App): void
}
