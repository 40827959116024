export function throttle(fn: any, wait: number) {
  let throttled = false
  return function(...args: any) {
    if (!throttled) {
      //@ts-ignore
      fn.apply(this, args)
      throttled = true
      setTimeout(() => {
        throttled = false
      }, wait)
    }
  }
}
